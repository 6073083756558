import {
  Box,
  CircularProgress,
  Stack
} from "@mui/material";
import moment from "moment";
import { RightDrawer } from "src/components/right-drawer";
import { MercadoLivreOrderDetails } from "./order-detail/meli-order-detail";
import { useEffect, useState } from "react";
import { ShopeeOrderDetails } from "./order-detail/shopee-order-detail";
import toast from "react-hot-toast";
import { api } from "src/services/api";

moment.locale("pt-br");

export const OrderDrawer = ({ open, activeChat, setOpen, internalData, setSelectedId }) => { 

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(true);
  const [order, setOrder] = useState(internalData || {});
  
  const getOrders = async () => {
    setLoading(true);
    try {

    if(activeChat?.conversation_type !== "chats") {
      const response = await api.get(
        `/v1/messages/${activeChat.id}/order_details`
      );   
      
      setOrder(response.data);
      setSuccess(true);
    }
    } catch (error) {
      toast.error("Falha ao buscar detalhes do pedido", {
        position: "bottom-right",
      });
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleEscPress = (event) => {
      if (event.key === "Escape") {
        setOpen(false);
      }
    };

    document.addEventListener("keydown", handleEscPress);
    
    return () => {
      document.removeEventListener("keydown", handleEscPress);
    };
  }, []);

  useEffect(() => {
    if (!internalData && (activeChat?.conversation_type === "messages" || activeChat?.conversation_type === "claims" || activeChat?.conversation_type === "chats")) {
      getOrders();
    }
  }, [activeChat, internalData]);

  const platform_name = activeChat?.account?.platform_name || activeChat?.platform_name;

  useEffect(() => {
    if (order?.order_items) {
      const updatedOrderItems = order.order_items.map((order_item) => {
        return {
          ...order_item,
          item: {
            ...order_item.item,
            catalog_listing: activeChat.catalog.find(
              (mlb_item) => mlb_item.announcement_id === order_item.item.id
            )?.catalog_listing ?? false,
          },
        };
      });
      if (order?.order_items[0].item.catalog_listing === undefined) {
        setOrder((prevOrder) => ({
          ...prevOrder,
          order_items: updatedOrderItems,
        }));
      }
    }
  }, [order]);

  return (
    <RightDrawer open={open}>
    <Stack px={1} py={2}>
      {loading ? (
         <Box
         display="flex"
         justifyContent="center"
         alignItems="center"
         sx={{
           height: "100%",
           width: "100%",
           position: "absolute",
           top: 0,
           left: 0,
         }}
       >
         <CircularProgress size="3rem" />
       </Box>
      ) : success && platform_name === "MercadoLivre" ? (
        <MercadoLivreOrderDetails
          order={order}
          setOrder={setOrder}
          activeChat={activeChat}
          setOpen={setOpen}
          loading={loading}
          setLoading={setLoading}
          success={success}
          setSuccess={setSuccess}
        />
      ) : (
        <ShopeeOrderDetails
          order={order}
          activeChat={activeChat}
          setOpen={setOpen}
          loading={loading}
          setLoading={setLoading}
          success={success}
          setSuccess={setSuccess}
          setSelectedId={setSelectedId}
        />
      )}
    </Stack>
  </RightDrawer>
  );
};
